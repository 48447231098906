import React , { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';

class Loader extends Component {
  constructor ( props ) {
    super ( props )
    this.state = {

    }
  }
  render () {
    return (
      <div style={{ display : 'flex' , justifyContent : 'center' , alignItems : 'center' , height : '100vh' }} >
        <CircularProgress size={80} />
      </div>
    )
  }
}

export default Loader
