import React, { Suspense, lazy, Component } from 'react';
import {BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Cargar from './components/loader';
import axios from 'axios'

import {
  Collapse,
  IconButton
} from'@material-ui/core'

import {
  Close
} from'@material-ui/icons'

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import './App.css';

import Procesosconstantes from './constantes';

const Inicio = lazy(() => import('./components/home/index.js'));
const Citas = lazy(() => import('./components/citas/index.js'));
const Pacientes = lazy(() => import('./components/pacientes/index.js'));
const Perfil = lazy(() => import('./components/pacientes/perfil.js'));
const Login = lazy(() => import('./components/login/index.js'));
const Asistentes = lazy(() => import('./components/asistentes/index.js'));
const PerfilA = lazy(() => import('./components/asistentes/perfil.js'));
const MenuComponent = lazy(() => import('./components/menu/index.js'));

let user = JSON.parse(localStorage.getItem(Procesosconstantes.USER))

class App extends Component {
  constructor (props) {
    super (props)
    this.state = {
      color:'warning',
      severity:'warning',
      content:'',
      openalert : false,
      users : null,
      pacientes : null,
      citas : null
    }
  }

  componentDidMount () {
    if (user) {
      this.setState({ user })
      if (user.accessLevel > 2) {
        this.getusers()
      }
      this.getpacientes()
      this.getcitas()
    }
  }

  openalert = (color, severity, content) => {
    this.setState({
      color,
      severity,
      content,
      openalert : true
    })
  }

  getusers = e => {
    let option = {
      url : `/api/sessionadmin/all`,
      method :'GET',
      headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json; charset = UTF-8',
        //'user-uid' : this.state.user.uid,
        'firma-app' : 'FFfirM4AAa4ll3AMNZDt487AaPp-9657eew$21.-87'
      }
    };

    axios(option)
    .then(response => {
      if (response.data.auth) {
        let usersfinal = response.data.users.filter(el => el.uid !== user.user_id)
        this.setState({
          users : usersfinal
        })
      } else {
        this.setState({
          users : []
        })
      }
    })
    .catch(e => {
      console.log(e);
    })
  }

  getpacientes = e => {
    let option = {
      url : `/api/pacientes/all`,
      method :'GET',
      headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json; charset = UTF-8',
        //'user-uid' : this.state.user.uid,
        'firma-app' : 'FFfirM4AAa4ll3AMNZDt487AaPp-9657eew$21.-87'
      }
    };

    axios(option)
    .then(response => {
      if (response.data.auth) {
        this.setState({
          pacientes : response.data.pacientes
        })
      } else {
        this.setState({
          pacientes : []
        })
      }
    })
    .catch(e => {
      console.log(e);
    })
  }

  getcitas = e => {
    let option = {
      url : `/api/citas/all`,
      method :'GET',
      headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json; charset = UTF-8',
        //'user-uid' : this.state.user.uid,
        'firma-app' : 'FFfirM4AAa4ll3AMNZDt487AaPp-9657eew$21.-87'
      }
    };

    axios(option)
    .then(response => {
      if (response.data.auth) {
        this.setState({
          citas : response.data.citas
        })
        console.log(response.data.citas);
      } else {
        this.setState({
          citas : []
        })
      }
    })
    .catch(e => {
      console.log(e);
    })
  }

  restart = (lista) => {
    if (lista.lists) {
      this.getpacientes()
    }
    if (lista.agentes) {
      this.getusers()
    }
  }

  addrows = (row) => {
    if (this.state.users.length > 0) {
      let current = this.state.users.filter(el => el.uid !== row.uid)
      this.setState({ users : [ row , ...current ] })
    } else {
      this.setState({ users : [ row ] })
    }
  }

  addrows2 = (row) => {
    if (this.state.pacientes.length > 0) {
      this.setState({ pacientes : [ ...this.state.pacientes , row ] })
    } else {
      this.setState({ pacientes : [ row ] })
    }
  }

  render () {

    const theme = createTheme({
      palette: {
        primary: {
          main:'#23A2AB',
          contrastTextText : "#fff"
        },
        grey: {
          main: '#777',
        },
        secondary: {
          main: '#23A2AB',
          contrastText : '#fff'
        },
        default : {
          main : "#f00"
        },
        danger : {
          main : "#f00"
        }
      }
    });
    return !user ?
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <Suspense fallback={<Cargar/>} >
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to='/login' />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    : (
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <Suspense fallback={<Cargar/>} >
            <MenuComponent user={user} />
            <Collapse in={this.state.openalert}>
              <Alert
                className="alert-formulario"
                severity={this.state.severity}
                color={this.state.color}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({ openalert : false })
                    }}
                  >
                    <Close />
                  </IconButton>
                }
              >
                {this.state.content}
              </Alert>
            </Collapse>
            <Routes>
              <Route exact path="/" element={<Inicio items={this.state.citas} user={user} />} />
              <Route exact path="/citas" element={<Citas items={this.state.citas} pacientes={this.state.pacientes} openalert={this.openalert} user={user} />} />
              <Route exact path="/miperfil" element={<PerfilA openalert={this.openalert} user={user} />} />
              <Route exact path="/perfil/:id" element={<Perfil openalert={this.openalert} user={user} />} />
              <Route exact path="/pacientes" element={<Pacientes addrows2={this.addrows2} restart={this.restart} pacientes={this.state.pacientes} openalert={this.openalert} user={user} />} />
              {user.accessLevel > 2 ?
                <Route exact path="/asistentes" element={<Asistentes user={user} restart={this.restart} addrows={this.addrows} users={this.state.users} openalert={this.openalert} />} />
              :null}
              <Route path="*" element={<Navigate to='/' />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
